<template>
	<h1>Copy Text</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import copyText from '@/use/CopyText'

export default {
	setup() {
		return { copyText }
	}
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		Example usage: Admin overlay module
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>Click either link and paste the result.</p>
		<a href="#" @click.prevent="e => copyText({ text: 'You\'ve copied Picasso', elm: e.target })">Picasso</a>
		/
		<a href="#" @click.prevent="e => copyText({ text: 'You\'ve copied Mondrian', elm: e.target })"
			>Mondrian</a
		>
		/
		<a href="#" @click.prevent="e => copyText({ text: 'You\'ve copied Matisse', elm: e.target })">Matisse</a>
	</div>
</template>

<script>
import copyText from '@/use/CopyText'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocModulesCopyText',
	setup() {
		injectMetaData({
			title: 'Doc: Copy Text',
		})
		return { copyText }
	},
	data() {
		return {
			html: [
				'<a href="#" @click.prevent="e => copyText({',
				`	text: 'You\\'ve copied Matisse'`,
				'	elm: e.target',
				'})">Matisse</a>',
			].join('\n'),
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss"></style>
